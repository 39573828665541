
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { filter } from "lodash";
import { ProjectsModule } from '@/store/modules/projects.module';
import ProjectList from '@/components/projects/project-list.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import EmptyState from '@/components/EmptyState.vue';
import { RouteNames } from '@/constants';
import HelpLink from '@/components/HelpLink.vue';
import ProjectEmptyState from '@/components/projects/ProjectEmptyState.vue';
import GAnalytics from '@/components/GAnalytics';
import { ClickData } from '@/analytics';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import FeatureFlag from '@/components/FeatureFlag';
import ProjectTemplate from '@/views/ProjectTemplate.vue';
import TablePagination from '@/components/table/TablePagination.vue';
import * as Projects from '@/types/projects.types';
import { userHasRole } from '@/utils/roles';
import { EnumsModule } from '@/store/modules/enums.module';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from '@/assets/projects.svg';

@Component({
  components: {
    ProjectTemplate,
    ProjectList,
    GButton,
    MaxWidth,
    EmptyState,
    HelpLink,
    ProjectEmptyState,
    FeatureFlag,
    GAnalytics,
    TablePagination,
    GTextfield,
  },
})
export default class ProjectsListView extends Vue {
  protected filters: { search: string } = {
    search: '',
  };
  protected listMode = 'cards';
  private newProjectOpen = false;
  private error = false;
  private loading = true;
  private page = 1;
  private perPage = 25;
  private value = '';
  private selectedProjects: Projects.ProjectListProject[] = [];
  public isAdminMode = false;

  get isProjectAdmin(): boolean {
    return userHasRole(EnumsModule.enums.role.PROJECTSUPERADMINISTRATOR.id);
  }

  get adminMode() : boolean {
    return ProjectsModule.isAdminMode;
  }

  async useAdminMode() {
    ProjectsModule.setAdminMode(!this.adminMode)
    this.loadProjects();
  }

  loadProjects() {
    // Reset Page Number
    this.page = 1;
    const method = this.adminMode ? 'getAllProjects' : 'getProjects';
    return ProjectsModule[method]()
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get newProjectAnalytics(): ClickData {
    return {
      clickTarget: 'project-list-new-project-button',
    };
  }

  get newProjectLink(): Location {
    return {
      name: RouteNames.NewProject,
    };
  }

  get headerStyle() {
    return {
      backgroundColor: 'var(--theme-lightest)',
    };
  }

  protected changeProjectViewMode(mode: string) {
    this.listMode = mode;
  }

  protected searchProject(val: string) {
    this.filters.search = val;
  }

  async created() {
    await this.loadProjects();
    this.loading = false;
  }

  async mounted() {
    if(this.$route.query?.q) {
      this.value = this.$route.query.q as string;
    }
    this.onSearch(this.value);
  }

  get projects() {
    return ProjectsModule.projects;
  }

  get filteredProjects() {
    const projects = this.value.length > 0 ? this.selectedProjects : ProjectsModule.projects;
    return projects;
  }

  get projectData() {
    const start = (this.page - 1) * this.perPage;
    const end = start + this.perPage;
    return this.filteredProjects.slice(start, end);
  }

  onSearch(value: string) {
    this.$router.replace({query: {q: value}}).catch(()=>{});
    this.selectedProjects = this.projects.filter(project =>
        project.projectName.toLowerCase().includes(value.toLowerCase().trim()) ||
        project.environments.some(env =>
          env.clientId?.toLowerCase()?.includes(value.toLowerCase().trim()),
        ) ||
        project.environments.some(environment =>
          environment.registrations.some(registration =>
            registration.kong_service_name?.toLowerCase()?.includes(value.toLowerCase().trim()),
        )
      )
    );
    this.page = 1;
  }

  get newProjectsLink() {
    return {
      name: RouteNames.NewProject,
    };
  }

  get emptyImageSrc() {
    return img;
  }

  get yup(): boolean {
    return FeatureFlagsModule.botPromotionEnabled;
  }
}
