
import { Component, Prop, Vue } from 'vue-property-decorator';
import uniqBy from 'lodash/uniqBy';
import truncate from 'lodash.truncate';
import { Project, ProjectUser, ProjectEnvironment } from '@/types/projects.types';
import UserList from '@/components/UserList.vue';
import GskSwitch from "@/components/gsk-components/GskSwitch.vue";
import { ProjectsModule } from '@/store/modules/projects.module';
import * as API from '@/api/projects.api';
import { UserModule } from '@/store/modules/user.module';
import { User } from '@/types/users.types';
import debounce from 'lodash/debounce';
import { openErrorSnackbar, openSuccessSnackbar } from '@/utils/components';
import PlatformTypeChip from '@/components/PlatformTypeChip.vue';
import { Platform } from '@/types/enum.types';
import { Platforms } from '@/constants';


@Component({
  components: {
    UserList,
    GskSwitch,
    PlatformTypeChip
  },
})
export default class ProjectCard extends Vue {
  public manageProject = false;
  public windowWidth: any = window.innerWidth;
  @Prop() readonly projectDetail!: Project;
  get truncatedDetails() {
    return truncate(this.projectDetail.projectDescription, { length: 160 });
  }

  get isAdminMode() {
    return ProjectsModule.isAdminMode
  }

  get environments(): ProjectEnvironment[] {
    return this.projectDetail.environments;
  }

  get env(): string {
    return this.environments?.[0]?.environmentName ?? '';
  }

  get name(): string {
    return this.projectDetail.projectName;
  }

  get currentUser(): User {
    return UserModule.user;
  }

  get tooltipPosition() {
    return this.windowWidth <= 1440? 'after': 'below';
  }
  onResize() {
    this.windowWidth = window.innerWidth;
  }
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  }

  get users(): ProjectUser[] {
    const { projectUsers, teams } = this.projectDetail;
    const teamMembers = teams.flatMap(team => team.teamMembers).filter(el => el !== null);
    const uniqueProjectUsers = uniqBy(projectUsers.concat(teamMembers), user => user.email);
    if(this.isAdminMode){
      const currentUserIsAnOwner = uniqueProjectUsers.find(owner => owner.mudId === this.currentUser.mudId);
      if(currentUserIsAnOwner){
        this.manageProject = true;
      }
    }
    
    return uniqueProjectUsers;
  }

  get platform() {
    const mnemonic =  this.projectDetail.platform?.mnemonic || Platform.AZURE;
    return Platforms[mnemonic].readableName;
  }

  public changeMode = debounce(this.changeModeFunc, 100);
  public async changeModeFunc(status: any) {
    this.manageProject = status;
    try{
      await API.manageThisProject(this.projectDetail.projectId,this.manageProject);
      if(this.manageProject) {
        ProjectsModule.addUserToProjectId({ projectId: this.projectDetail.projectId, userInfo: this.currentUser });
        openSuccessSnackbar.call(this, 'Project owned successfully.');
      }
      else {
        ProjectsModule.removeUserFromProject({ projectId: this.projectDetail.projectId, userInfo: this.currentUser });
        openSuccessSnackbar.call(this, 'Project reassigned successfully.');
      }
        
    }
    catch(e){
      this.manageProject = !this.manageProject;
      openErrorSnackbar.call(this, 'Unable to assign, something went wrong.');
    }
    
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  }
}
