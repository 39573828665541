
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProjectCard from '@/components/ProjectCard.vue';
import { Project } from '@/types/projects.types';
import MaxWidth from '@/components/MaxWidth.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';

@Component({
  components: {
    ProjectCard,
    MaxWidth,
    GButton,
    GAnalytics,
  },
})
export default class ProjectList extends Vue {
  @Prop()
  protected projects!: Project[];

  projectLinkAnalytics(project: Project): ClickData {
    return {
      clickTarget: 'project-list-card',
      projectId: project.projectId,
      projectName: project.projectName,
    };
  }

  projectLink(project: Project, evt: any) {
    if(evt.target && evt.target.name !== 'admin-mode-switch') {
      this.$router.push({
        name: 'project-details',
        params: addAnalyticsRouteParams(
          { id: project.projectId.toString() },
          {
            projectName: project.projectName,
          },
        ),
      }).catch(() => undefined);
    }
  }
}
