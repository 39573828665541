
import { Component, Vue } from 'vue-property-decorator';
import GButton from '@/components/gsk-components/GskButton.vue';
import { RouteNames } from '@/constants';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import FeatureFlag from '@/components/FeatureFlag';

@Component({
  components: {
    GButton,
    Grid,
    GridCell,
    GDialog,
    FeatureFlag,
  },
})
export default class ProjectEmptyState extends Vue {
  public openUnavailableDialog = false;

  get newRpaRoute() {
    return {
      name: RouteNames.NewRpaProject,
      params: {
        type: 'project',
      },
    };
  }
  get newCicdRoute() {
    return {
      name: RouteNames.NewProjectForm,
      params: {
        type: 'cicd',
      },
    };
  }
  get newApiRoute() {
    return {
      name: RouteNames.NewProjectForm,
      params: {
        type: 'api',
      },
    };
  }

  get kongEnabled(): boolean {
    return FeatureFlagsModule.kongEnabled;
  }

  showUnavailableDialog(): void {
    this.openUnavailableDialog = true;
  }
}
